/***** BACKGROUND COLOR FIX FOR IE *****/

.background-white-ie-fix {
  background: white;
}

/***** MOBILE FONTS *****/

.heading-font {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
  color: white;
}

.confirmation-font {
  font-family: "Roboto Slab", serif;
  font-weight: 600;
  font-size: 1.25rem;
  color: white;
}

.body-font {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-size: 1rem;
}

/***** DESKTOP FONTS *****/

@media (min-width: 766px) {
  .heading-font {
    font-size: 2.5rem;
  }
  .confirmation-font {
    font-size: 1.5rem;
  }
  .body-font {
    font-size: 1.25rem;
  }
}

/***** MISC *****/

.disabled-button-style:disabled {
  background-color: rgba(129, 127, 127, 0.95);
}

.disabled-button-style:hover:disabled {
  background-color: rgba(129, 127, 127, 0.95);
  cursor: not-allowed;
}

/***** HIDE ARROWS ON NUMBER INPUTS *****/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
